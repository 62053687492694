import React, { useState, useEffect, useCallback } from "react";
import img1 from "../../../images/img2.webp";
import img2 from "../../../images/img1.webp";
import aws from "../../../images/aws-logo.svg";
import microsoft from "../../../images/micro.svg";
import google from "../../../images/google.svg";
function AboutUs({ aboutData }) {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  return (
    <div className="aboutUs pt-5">
      <div className="global-margin">
        <div className="containerwrap-about row">
          <div className="aboutUsLeftSection col-md-6 col-12">
            <h2
              className={` ${
                isMobileView ? "aboutUsTitle  text-center " : "aboutUsTitle "
              }`}
            >
              {aboutData.title}
            </h2>
            <p
              style={{ color: "white" }}
              className={` ${
                isMobileView ? "aboutUsHeading  text-center" : "aboutUsHeading "
              }`}
            >
              {aboutData.heading}
            </p>
            <div
              className={` ${
                isMobileView
                  ? "aboutUsDescription  text-center"
                  : "aboutUsDescription "
              }`}
              style={{ color: "white" }}
            >
              {aboutData.description}
            </div>
            <div className=" desktop">
              <div className="aboutUslogoSectionTitle">
                <div className="text-style  text-center">
                  {aboutData.specialization}
                </div>
                <div className="brands d-flex pt-4 flex-column">
                  <div className="aboutUslogoSection">
                    <figure>
                      <img
                        className="pt-3 me-4 img-item"
                        src={aws}
                        alt="Amazon Web Services"
                      />
                    </figure>
                  </div>
                  <div className="aboutUslogoSection">
                    <figure>
                      <img
                        className="pt-3 me-4 img-item"
                        src={microsoft}
                        alt="Azure"
                      />
                    </figure>
                  </div>
                  <div className="aboutUslogoSection ">
                    <figure>
                      <img
                        className="pt-3 me-4 img-item"
                        src={google}
                        srcset={`${google} 2x , ${google} 3x `}
                        alt="Google Cloud"
                        loading="lazy"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="aboutUsRightSection col-md-6 col-12 mt-5">
            <div className="row me-3">
              <div
                className={` ${
                  isMobileView
                    ? "col-12 padding-none"
                    : "col-md-6 col-6 padding-none"
                }`}
              >
                <img
                  src={img1}
                  alt="aws"
                  className={` ${
                    isMobileView
                      ? "right_padding heading-img w-100 my-4"
                      : "right_padding heading-img"
                  }`}
                />
              </div>
              <div
                className={` ${
                  isMobileView
                    ? "col-12 padding-none"
                    : "col-md-6 col-6 padding-none"
                }`}
              >
                <img
                  src={img2}
                  alt="aws"
                  className={` ${
                    isMobileView ? "heading-img w-100" : "heading-img"
                  }`}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="containerwrap-about row mobile mt-5">
          <div className="aboutUsLeftSection col-md-8 col-12">
            <div>
              <div className="aboutUslogoSectionTitle about-padding">
                <div className="text-style ">{aboutData.specialization}</div>
                <div className="brands d-flex pt-4">
                  <div className="aboutUslogoSection ps-3">
                    <figure>
                      <img
                        className="pt-3 me-4"
                        width="80"
                        src={aws}
                        alt="Amazon Web Services"
                      />
                    </figure>
                  </div>
                  <div className="aboutUslogoSection ps-3">
                    <figure>
                      <img
                        width="200"
                        className="pt-4 me-2"
                        src={microsoft}
                        alt="Azure"
                      />
                    </figure>
                  </div>
                  <div className="aboutUslogoSection  mx-4">
                    <figure>
                      <img
                        className="pt-4 me-4"
                        width="192"
                        src={google}
                        srcset={`${google} 2x , ${google} 3x `}
                        alt="Google Cloud"
                        loading="lazy"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
