import React, { useMemo } from "react";
import Layout from "../components/layoutnew";
import Heading from "../components/whyOpsio/aboutUs/Heading";
import AboutUs from "../components/whyOpsio/aboutUs/AboutUs";
import SuccessStories from "../components/whyOpsio/aboutUs/SuccessStories";
import Mission from "../components/whyOpsio/aboutUs/Mission";
import CardAbout from "../components/whyOpsio/aboutUs/CardAbout";
import { graphql } from "gatsby";
import "../styles/about.scss";
import SEO from "../components/seo";
import "../styles/index.scss";
import "../styles/index2.scss";
import "../styles/home.scss";

const AboutUsOpsio = ({ data }) => {
  const getPlatformData = (data) => {
    const values = data.allStrapiAboutus.edges[0].node;
    return values;
  };
  const getData = useMemo(() => getPlatformData(data), [data]);
  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        slug={getData.slug}
        locale={getData.locale}
        title={getData.metaTitle}
        description={getData.metaDes}
      />
      <Heading heroSection={getData.herosection} locale={getData.locale} />
      <AboutUs aboutData={getData.aboutus} />
      <SuccessStories storyData={getData.ourstory} locale={getData.locale} />
      <Mission missionData={getData.ourmission} />
      <CardAbout teamData={getData.team} />
      {/* <Location locale={getData.locale} /> */}
    </Layout>
  );
};

export default AboutUsOpsio;

export const query = graphql`
  query AboutUsOpsio($locale: String!) {
    allStrapiAboutus(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          slug
          locale
          metaTitle
          metaDes
          aboutus {
            title
            heading
            description
            description2
            description3
            description4
            heading5
            specialization
          }
          herosection {
            title
            heading1
          }
          ourmission {
            title
            heading
            description1
            description2
          }
          ourstory {
            title
            heading
            description
            story {
              cardDes
              cardTitle
              list
              timeline
            }
            detail {
              techExpert
              certifications
              projectDelivered
              yearsOfExperience
            }
          }
          team {
            title
            heading
            des
          }
        }
      }
    }
  }
`;
