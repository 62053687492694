import React, { useState, useEffect } from "react";

import "../../../styles/about.scss";
import noImg from "../../../images/noImage.png";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import arrowLeft from "../../../images/hallowArrowLeft.svg";
import arrowRight from "../../../images/hallowArrowRight.svg";

function CardAbout({ teamData }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  let data = useStaticQuery(graphql`
    query {
      allStrapiOpsioTeam {
        edges {
          node {
            name
            number
            email
            profile
            image {
              localFile {
                childImageSharp {
                  fixed {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
        }
      }
    }
  `).allStrapiOpsioTeam.edges;

  data = data.filter(
    (val) => val.node.name !== "Sujatha Kamal" && val.node && val.node.image
  );

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? 0 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === data.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="team_page" style={{ backgroundColor: "#17263F" }}>
      <div className="global-margin">
        <div
          className={` ${
            isMobileView
              ? "platformHeroSectionTitle  text-center"
              : "platformHeroSectionTitle  ms-3"
          }`}
        >
          {teamData.title}
        </div>
        <div
          className={` ${
            isMobileView
              ? "platformHeroSectionHeading text-center"
              : "platformHeroSectionHeading ms-3"
          }`}
        >
          {teamData.heading}
        </div>
        <div
          className={` ${
            isMobileView
              ? "font-size-14 line-height-20 font-weight-400 text-white  text-center"
              : "platformHeroSectiondes ms-3"
          }`}
        >
          {teamData.des}
        </div>
        <div className="aboutFilterOptions">
          {[...data.slice(currentIndex, currentIndex + 4)].map((val, index) => {
            return (
              <div
                className={` ${
                  isMobileView
                    ? "aboutFilterOption col-12 position-relative h-100"
                    : "aboutFilterOption col-md-3 col-6 position-relative "
                }`}
                key={index}
              >
                {val.node.name !== "Jessica Johansson" && (
                  <div className="card-background  py-3 d-flex flex-column align-items-center">
                    <div className="">
                      <figure>
                        {val.node.image &&
                        val.node.image.localFile &&
                        val.node.image.localFile.childImageSharp &&
                        val.node.image.localFile.childImageSharp.fixed ? (
                          <Img
                            fixed={
                              val.node.image.localFile.childImageSharp.fixed
                            }
                            alt={val.node.name}
                            className="aboutFilterOptionImg"
                          />
                        ) : (
                          <img
                            src={noImg}
                            alt="about"
                            className="aboutFilterOptionImg"
                          />
                        )}
                      </figure>
                    </div>
                    <div className="d-flex">
                      {index === 0 && currentIndex !== 0 && (
                        <div
                          role="button"
                          className="position-absolute slider-left cursor-pointer"
                          onClick={handlePrev}
                        >
                          <img src={arrowLeft} alt="arrow left" />
                        </div>
                      )}

                      {index === 3 && currentIndex !== data.length - 1 && (
                        <div
                          role="button"
                          className="position-absolute slider-right cursor-pointer"
                          onClick={handleNext}
                        >
                          <img src={arrowRight} alt="arrow right" />
                        </div>
                      )}

                      <div className="aboutFilterMobileMargin ps-2 d-flex flex-column align-items-center">
                        <div className="aboutFilterOptionName text-uppercase ">
                          {val.node.name}
                        </div>
                        <div
                          className={` ${
                            isMobileView
                              ? "font-size-20 aboutFilterProfile text-uppercase text-center mt-2"
                              : "aboutFilterProfile text-uppercase text-center mt-2"
                          }`}
                        >
                          {val.node.profile}
                        </div>
                        <div className="aboutFilterOptionDes">
                          <div>{val.node.email}</div>
                          <div>{val.node.number}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CardAbout;
