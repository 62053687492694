import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import line from "../../../../src/images/Line 7.svg";
import line2 from "../../../../src/images/Line 8.svg";
import dot from "../../../../src/images/bluedot.svg";

import fifty from "../../../../src/images/50.png";
import threek from "../../../../src/images/3000.svg";
import fifteen from "../../../../src/images/15.svg";
import onehundered from "../../../../src/images/100.svg";
import plus from "../../../../src/images/+.svg";
import rectangle from "../../../../src/images/rectangle.png";
import MediaQuery from "react-responsive";

function SuccessStories({ storyData, locale = "en" }) {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);
  return (
    <div className="aboutSuccessStories">
      <div className="global-margin">
        <div
          className={` ${
            isMobileView
              ? "font-size-24 line-height-35 font-weight-400 light-blue-color text-center  text-center"
              : "aboutSuccesssStoryTitle "
          }`}
        >
          {storyData.title}
        </div>
        <div
          className={` ${
            isMobileView
              ? "font-size-24 line-height-35 font-weight-400 text-light my-5 roboto text-center"
              : "aboutSuccessStoryHeading text-light my-5 roboto"
          }`}
        >
          {storyData.heading}
        </div>
        <div
          className={` ${
            isMobileView
              ? `aboutSuccesssStoryText text-light text-start text-center ${
                  locale !== "sv" && "capitalize"
                }`
              : `aboutSuccesssStoryText text-light text-start ${
                  locale !== "sv" && "capitalize"
                }`
          }`}
        >
          {storyData.description}
        </div>
        <div className="abtSucStCards">
          <MediaQuery minWidth={1025}>
            {/* <Slider> */}
            <div className="">
              <div className="">
                <div className="year">{storyData.story[0].timeline}</div>
                <div className="row ">
                  {/* <div className="abtSucStCardLeftSec card1 col-md-5 col-12"> */}
                  <div className="col-md-12 col-12 mt-5">
                    {/* <img className="p-0 mx-1 my-2" src={one} alt="one"/> */}
                    <h4 className="mt-3 pb-4 about-title timeline-caption color-green mx-1 my-5">
                      {storyData.story[0].timeline}
                    </h4>
                    <h5
                      className={`mt-3 pb-4 about-title ${
                        locale !== "sv" && "capitalize"
                      }`}
                    >
                      {storyData.story[0].cardTitle}
                    </h5>

                    <div className="row">
                      <div
                        className={`text-light about1-text col-6 ${
                          locale !== "sv" && "capitalize"
                        } font-weight-400 font-size-20 line-height-35`}
                      >
                        {storyData.story[0].cardDes}
                      </div>
                      <div className="col-6 text-end">
                        <img className="w-50" src={rectangle} alt="rectangle" />
                      </div>
                    </div>
                    <img className="about-dot p-0" src={dot} alt="dot" />
                    <img
                      className="px-5 w-100 about-line ps-3"
                      src={line}
                      alt="line"
                    />
                    {/* <img className="p-3 ms-3" src={dot} /> */}
                  </div>
                  <div></div>
                  <div className="d-flex justify-content-end p-0 mx-1">
                    <img className="me-5 my-2" src={dot} alt="dot" />
                  </div>
                  <div className="row px-0 mt-4 py-4">
                    <div className="col-6">
                      <img className="w-50" src={rectangle} alt="rectangle" />
                    </div>
                    <div className="col-6 text-end px-0 ">
                      {/* <img className="my-2" src={two} alt="two"/> */}
                      <h4 className="mt-3 pb-4 about-title timeline-caption color-green mx-1 my-5">
                        {storyData.story[1].timeline}
                      </h4>
                      <h5
                        className={`text-end  pb-4 about-title ${
                          locale !== "sv" && "capitalize"
                        }`}
                      >
                        {storyData.story[1].cardTitle}
                      </h5>
                      <div
                        className={`text-light text-end ${
                          locale !== "sv" && "capitalize"
                        } font-weight-400 font-size-20 line-height-35`}
                      >
                        {storyData.story[1].cardDes}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end"></div>
                  <div className="d-flex justify-content-end "></div>
                  <div className="sldier_cotnent col-md-6 col-12"></div>
                </div>
              </div>
            </div>

            <div className=" ">
              <div className="abtSucStCard cardList">
                <div className="row">
                  <div className=" card2 col-md-7 col-12">
                    <div className="d-flex justify-content-end"></div>
                    <div>
                      <div className="d-flex justify-content-lg-end">
                        <img
                          className="pe-5 dot-position text-end"
                          src={dot}
                          alt="dot"
                        />
                      </div>
                      <div>
                        <img
                          className="about-line line2-position line-position"
                          src={line2}
                          alt="line2"
                        />
                      </div>
                      <div>
                        <img
                          className="px-4  line-position"
                          src={dot}
                          alt="dot"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row totop-150">
                  <div className="text-light col-6 ">
                    <div>
                      {/* <img className="p-2 my-2" src={three} alt="three"/> */}
                      <h4 className="mt-3 pb-4 about-title timeline-caption color-green mx-1 my-5">
                        {storyData.story[2].timeline}
                      </h4>
                      <h5
                        className={`text-start about-title pb-4 ${
                          locale !== "sv" && "capitalize"
                        }`}
                      >
                        {storyData.story[2].cardTitle}
                      </h5>
                    </div>
                    <div
                      className={`text-light ${
                        locale !== "sv" && "capitalize"
                      } font-weight-400 font-size-20 line-height-35`}
                    >
                      {storyData.story[2].cardDes}
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <img
                      className="w-50 mt-4"
                      src={rectangle}
                      alt="rectangle"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div className="">
                <div className="row ">
                  {/* <div className="abtSucStCardLeftSec card1 col-md-5 col-12"> */}
                  <div className="col-md-12 col-12 mt-5">
                    <img className="about-dot p-0" src={dot} alt="dot" />
                    <img
                      className="px-5 w-100 about-line ps-3"
                      src={line}
                      alt="line"
                    />
                    {/* <img className="p-3 ms-3" src={dot} /> */}
                  </div>
                  <div></div>
                  <div className="d-flex justify-content-end p-0 mx-1">
                    <img className="me-5 my-2" src={dot} alt="dot" />
                  </div>
                  <div className="row px-0 mt-4 py-4">
                    <div className="col-6">
                      <img className="w-50" src={rectangle} alt="rectangle" />
                    </div>
                    <div className="col-6 text-end px-0 ">
                      {/* <img className="my-2" src={four} alt="two"/> */}
                      <h4 className="mt-3 pb-4 about-title timeline-caption color-green mx-1 my-5">
                        {storyData.story[3].timeline}
                      </h4>
                      <h5
                        className={`text-end pb-4 about-title ${
                          locale !== "sv" && "capitalize"
                        }`}
                      >
                        {storyData.story[3].cardTitle}
                      </h5>
                      <div
                        className={`text-light text-end ${
                          locale !== "sv" && "capitalize"
                        } font-weight-400 font-size-20 line-height-35`}
                      >
                        {storyData.story[3].cardDes}
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end"></div>
                  <div className="d-flex justify-content-end "></div>
                  <div className="sldier_cotnent col-md-6 col-12"></div>
                </div>
              </div>
            </div>

            <div className=" ">
              <div className="abtSucStCard cardList">
                <div className="row">
                  <div className=" card2 col-md-7 col-12">
                    <div className="d-flex justify-content-end"></div>
                    <div>
                      <div className="d-flex justify-content-lg-end">
                        <img
                          className="pe-5 dot-position text-end"
                          src={dot}
                          alt="dot"
                        />
                      </div>
                      <div>
                        <img
                          className="about-line line2-position line-position"
                          src={line2}
                          alt="line2"
                        />
                      </div>
                      <div>
                        <img
                          className="px-4  line-position"
                          src={dot}
                          alt="dot"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row totop-150">
                  <div className="text-light col-6 ">
                    <div>
                      {/* <img className="p-2 my-2" src={five} alt="three"/> */}
                      <h4 className="mt-3 pb-4 about-title timeline-caption color-green mx-1 my-5">
                        {storyData.story[4].timeline}
                      </h4>
                      <h5
                        className={`text-start about-title pb-4 ${
                          locale !== "sv" && "capitalize"
                        }`}
                      >
                        {storyData.story[4].cardTitle}
                      </h5>
                    </div>
                    <div
                      className={`text-light ${
                        locale !== "sv" && "capitalize"
                      } font-weight-400 font-size-20 line-height-35`}
                    >
                      {storyData.story[4].cardDes}
                    </div>
                  </div>
                  <div className="col-6 text-end">
                    <img
                      className="w-50 mt-4"
                      src={rectangle}
                      alt="rectangle"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* </Slider> */}
          </MediaQuery>
        </div>
        <div className="abtSucStCard cardList">
          <div className="row pb-5">
            <div
              className={` ${
                isMobileView
                  ? "box my-5  p-5"
                  : "d-flex box my-5 justify-content-around p-5"
              }`}
            >
              <div className={` ${isMobileView ? "text-center" : ""}`}>
                <img
                  src={fifty}
                  className={` ${isMobileView ? "w-50 " : ""}`}
                  alt="fifty"
                />
                <img className="mx-4" src={plus} alt="plus" />
                <label
                  className={` ${
                    isMobileView
                      ? "text-light my-4 font-weight-400 font-size-14 line-height-20 roboto"
                      : "text-light mt-4 font-weight-500 font-size-24 line-height-35 roboto"
                  }`}
                >
                  {storyData.detail.techExpert}
                </label>
              </div>
              <div className={` ${isMobileView ? "text-center" : ""}`}>
                <img
                  src={threek}
                  className={` ${isMobileView ? "w-50" : ""}`}
                  alt="threek"
                />
                <img
                  className={` ${isMobileView ? "mx-4" : "mx-4"}`}
                  src={plus}
                  alt="plus"
                />
                <label
                  className={` ${
                    isMobileView
                      ? "text-light my-4 font-weight-400 font-size-14 line-height-20 roboto"
                      : "text-light mt-4 font-weight-500 font-size-24 line-height-35 roboto"
                  }`}
                >
                  {storyData.detail.projectDelivered}
                </label>
              </div>

              <div className={` ${isMobileView ? "text-center" : ""}`}>
                <img src={fifteen} alt="fifteen" />
                <img className="mx-4" src={plus} alt="plus" />
                <label
                  className={` ${
                    isMobileView
                      ? "text-light my-4 font-weight-400 font-size-14 line-height-20 roboto"
                      : "text-light mt-4 font-weight-500 font-size-24 line-height-35 roboto"
                  }`}
                >
                  {storyData.detail.yearsOfExperience}
                </label>
              </div>
              <div className={` ${isMobileView ? "text-center" : ""}`}>
                <img src={onehundered} alt="onehundered" />
                <img
                  className={` ${isMobileView ? "mx-2" : "mx-4"}`}
                  src={plus}
                  alt="plus"
                />
                <label
                  className={` ${
                    isMobileView
                      ? "text-light my-4 font-weight-400 font-size-14 line-height-20 roboto"
                      : "text-light mt-4 font-weight-500 font-size-24 line-height-35 roboto"
                  }`}
                >
                  {storyData.detail.certifications}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessStories;
