import React, { useState, useEffect } from "react";

function Mission({ missionData }) {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);
  return (
    <div>
      <div className="aboutMission">
        <div className="aboutUsLeftSection global-margin">
          <div
            className={` ${
              isMobileView
                ? "aboutMissionTitle text-uppercase text-center "
                : "aboutMissionTitle text-uppercase "
            }`}
          >
            {missionData.title}
          </div>
          <div
            className={` ${
              isMobileView ? "aboutUsHeading  text-center" : "aboutUsHeading "
            }`}
            style={{ marginTop: 0, height: "48px", color: "white" }}
          >
            {missionData.heading}
          </div>
          <div
            className={` ${
              isMobileView
                ? "aboutUsDescription  text-center"
                : "aboutUsDescription "
            }`}
            style={{ marginTop: "36px", color: "white" }}
          >
            {missionData.description1}
          </div>
          <div
            className={` ${
              isMobileView
                ? "aboutUsDescription  text-center"
                : "aboutUsDescription "
            }`}
            style={{ marginTop: "36px", color: "white" }}
          >
            {missionData.description2}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mission;
