import React, { useState, useEffect, useCallback } from "react";
import { navigate } from "gatsby";
import bgContact from "../../../images/opsio_contact.webp";

function Heading({ heroSection, locale = "en" }) {
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);
  return (
    <div className="upper_class">
      <div className="aboutSection1">
        <img className="bg-about" src={bgContact} loading="lazy" alt="bg" />
        <div className="containerwrap">
          <h4
            className={` ${
              isMobileView
                ? "font-size-24 line-height-35 font-weight-400 light-blue-color text-center "
                : "aboutSection1Title "
            }`}
          >
            {heroSection.title}
          </h4>
          <h1
            className={` ${
              isMobileView
                ? `font-size-20 line-height-35 font-weight-400  text-center text-white  roboto ${
                    locale === "en" && "capitalize"
                  }`
                : `aboutSection1Heading roboto ${
                    locale === "en" && "capitalize"
                  }`
            }`}
            style={{ marginBottom: "0px" }}
          >
            {heroSection.heading1}
            <span className={` ${isMobileView ? "light-blue-color " : ""}`}>
              {heroSection.heading2}
            </span>
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Heading;
